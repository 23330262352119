<template>
  <NewModelLayout>
    <template #title>
      <h1 class="text-2xl">
        Importer Tool
      </h1>
    </template>
    <template #form>
      <vue-form
        :form="form"
        :errors.sync="error.errors"
        ref="form"
        class="w-full"
      >
        <div class="flex flex-col space-y-4">
          <div class="bg-white shadow rounded-sm px-6 py-4">
            <p>You can import documents into the Vibox system using this tool, including products, prices, and game performance specs.</p>
            <p class="text-red-700">Selecting the wrong import type to the data you provide may cause fatal errors, be warned!</p>
          </div>
          <div class="bg-white shadow rounded-sm px-6 py-4">
            <div class="flex flex-wrap w-row-offset-sm -mx-2">
              <div class="w-full lg:w-1/2 px-2">
                <vue-input-group
                  v-model="form.type"
                  :data="importTypes"
                  prop="type"
                  label="Import Type"
                  description="Specify the type of data you are importing."
                  labelKey="label"
                  valueKey="value"
                  type="radio-group"
                />
              </div>
              <div class="w-full lg:w-1/2 px-2">
                <label for="file" class="font-medium text-sm">
                  Import Data
                </label>
                <vue-file
                  ref="file"
                  prop="file"
                  accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet,.xlsx,text/csv,.csv"
                  @change="syncFile"
                />
                <p class="text-xs opacity-75 my-1">
                  Specify an .XLSX or .CSV file to import data from.
                </p>
              </div>
            </div>
          </div>
          <div class="flex items-center justify-end w-full space-x-4">
            <font-awesome-icon v-if="loading" :icon="['fas', 'spinner-third']" size="lg" fixed-width spin />
            <vue-button
              :disabled="!form.file || !form.type || form.loading"
              type="submit"
              success
              @click.prevent="submitForm"
            >
              Start Import
            </vue-button>
          </div>
        </div>
      </vue-form>
      <vue-dialog :dialog.sync="dialog.show">
        <template #title>
          {{ dialog.title }}
        </template>
        {{ dialog.message }}
      </vue-dialog>
    </template>
  </NewModelLayout>
</template>
<script>
import NewModelLayout from '@/components/layouts/NewModelLayout'

export default {
  components: {
    NewModelLayout
  },

  data () {
    return {
      loading: false,
      error: [],
      form: {},
      importTypes: [
        {
          label: 'Products',
          value: 'products'
        },
        {
          label: 'Prices',
          value: 'prices'
        },
        {
          label: 'Games & Performance',
          value: 'game_specs'
        },
        {
          label: 'Pre-build Products',
          value: 'products_prebuilt'
        }
      ],
      dialog: {
        show: false,
        title: '',
        content: ''
      }
    }
  },

  methods: {
    syncFile (event) {
      this.$set(this.form, 'file', event.target.files.length ? event.target.files[0] : null)
    },

    submitForm () {
      this.loading = true
      if (this.$refs.form.validate()) {
        const formData = new FormData()
        formData.append('type', this.form.type)
        formData.append('file', this.form.file)
        this.$api.post('import', formData)
          .then((res) => {
            this.dialog = {
              show: true,
              title: 'Import was successful!'
            }
          })
          .catch((err) => {
            this.$store.commit('error/addError', err)
          })
          .finally(() => {
            this.loading = false
          })
      }
    }
  }
}
</script>
